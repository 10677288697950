$body-bg        : #ccc;

@import "~bootstrap/scss/bootstrap";
//Theme
@import "theme/variables";
@import "theme/mixins";
@import "theme/flexbox";
@import "theme/reset";


// Custom Scss
@import "theme/header";
@import "theme/home";
@import "theme/pages";
@import "theme/footer";
@import "theme/card";

//lib
//@import "~owl.carousel2/src/scss/owl.carousel";
@import "~magnific-popup/dist/magnific-popup.css";
//@import "~jquery-nice-select/scss/nice-select";
//@import "~animate.css";
@import "~@fortawesome/fontawesome-free/css/all.css";