// ============= Start Aboutus Page Styles  =============  //


//--------- Start Aboutus Area style -------------//

.link-nav{
	margin-top:10px;
}

.about-content{
	padding: 150px 0px;
	text-align:center;
	h1{
		font-size: 48px;
		font-weight:300;
	}
	a{
		color:$white;
		font-weight:300;
		font-size:14px;
	}
	.lnr{
		margin:0px 10px;
		font-weight:600;
	}
}

//--------- End Aboutus Area style -------------//

//--------- start about info area  -------------//


.info-area {
	.info-thumb {
		overflow: hidden;
		display: inline-block;
	}


	@media(max-width:960px){
		.info-content{
			text-align:center;
			padding: 80px 30px 80px 0;
		}
	}


	.info-content {
		box-shadow: 0px 0px 50px 0px rgba(157, 157, 157, 0.3);
		@include media-breakpoint-down(xs) {
			margin-top: 30px;
		}
		position: relative;
		&:after {
			content: "";
			box-shadow: -35.355px 35.355px 50px 0px rgba(157, 157, 157, 0.3);
			position: absolute;
			right: 100%;
			top: 0;
			background: $white;
			width: 70px;
			height: 100%;
			@include media-breakpoint-down(md) {
				width: 50px;
			}
			@include media-breakpoint-down(xs) {
				display: none;
			}
		}
		background: $white;
		padding: 80px 100px 80px 40px;
		@include media-breakpoint-down(md) {
			padding: 30px 30px 30px 0;
		}
		@include media-breakpoint-down(xs) {
			padding: 30px;
		}
		h1 {
			line-height: 1.15em;
			margin-bottom: 30px;
		}
		.meta{
			color:$black;
			font-weight:400;
			font-size:14px;
			margin-top:20px;
		}
	}

	.info-left{
		z-index:2;
		@media(max-width:800px){
			margin-top:0px;
			margin-bottom:40px;
		}
	}


}

//--------- end about info area  -------------//

// ============= End Aboutus Page Styles  =============  //


// ============= Start Category Page Styles  =============  //

//--------- start category top area  -------------//

.cat-top-area{
	background:$offwhite;
	h1{
		margin-bottom:20px;
	}
	@media(max-width:960px ){
		.cat-top-right{
			margin-top:50px;
			img{
				margin-left:auto!important;
				margin-right:auto!important;
				display:block;
			}
		}
	}
}


//--------- end category top area  -------------//



.single-recent-work{
	margin-bottom:30px;
	img{
		width:100%;
	}
}


// ============= End Category Page Styles  =============  //


// ============= Start Gallery Page Styles  =============  //


.gallery-page-area, .service-area{
	background:$white!important;
}


// ============= End Gallery Page Styles  =============  //



// ============= Start ticket Page Styles  =============  //


.single-exhibition{
	img{
		border-radius: 10px;
		margin-bottom:15px;
	}
	h4{
		margin:15px 0;
	}
	.price{
		color:$primary-color;
		font-size:18px;
		font-weight:700;
		margin-bottom:30px;
	}
}


// ============= End ticket Page Styles  =============  //

// ============= Start song Page Styles  =============  //
.song-area{
	background: $white;
}
// ============= End song Page Styles  =============  //



// ============= Start contact Page Styles  =============  //

.contact-page-area{
	background:$white;
	.map-wrap{
		margin-bottom:120px;
		@media(max-width:960px){
			margin-bottom:50px;
		}
	}

	.contact-btns{
		font-size:18px;
		font-weight:600;
		text-align:center;
		padding:40px;
		color:$white;
		margin-bottom:30px;
		&:hover{
			color:$white;
		}
	}

	.form-area{
		input{
			padding: 15px;
		}
		input,textarea{
			border-radius:0;
			font-size:12px;
		}
		textarea{
			height: 180px;
			margin-top: 0px;
		}

		.primary-btn{
			@include transition();
			&:hover{
				border:1px solid $primary-color;
				color:$primary-color!important;
			}
		}
	}

	@media(max-width: 960px){
		.address-wrap{
			margin-bottom: 50px;
		}
	}


	.single-contact-address {
		margin-bottom:20px;
		h5{
			font-weight:300;
			font-size:16px;
			margin-bottom:5px;
		}
		.lnr{
			font-size:30px;
			font-weight:500;
			color:$primary-color;
			margin-right:30px;
		}
	}

}


// ============= End contact Page Styles  =============  //



/*
################
               Start Blog Home  Page style
################
*/



//--------- Start Blog Banner Area Style-------------//
//Todo Fix
.blog-banner-area{
	//background:url(../img/elements/blog/blog-banner.jpg) center;
	background-size:cover;
	.overlay-bg{
		background:rgba(#000,.55);
	}
}

.blog-banner-wrap{
	text-align:center;
}


//--------- End Blog Banner Area Style-------------//


//--------- Start Blog Post Area Style-------------//

.blog-posts-area{

}

.blog-post-list{
	.single-post{
		background:transparent;
		padding:0px 30px;
	}
}


.single-post{
	margin-bottom:30px;
	.tags{
		margin-top:40px;
		li{
			display:inline-block;
			a{
				color:$black;
			}
			&:hover{
				a{
					color:$primary-color;
				}
			}
		}
	}
	h1{
		margin-top:20px;
		margin-bottom:20px;
	}

	.title{
		// margin-left:20px;
		h4{
			margin-bottom:10px;
		}
	}

	.comment-wrap,.social-wrap{
		ul li{
			display:inline-block;
			margin-right:15px;
		}
		@media(max-width: 414px){
			margin-top:10px;
		}
	}
	.comment-wrap{
		ul li a{
			color:$text-color;
		}
	}
	.social-wrap{
		ul{
			text-align:right;
			li a i{
				color:$text-color;
				&:hover{
					color:$black;
				}
			}
			@media(max-width: 768px){
				text-align:left;
			}
		}

	}
}


//--------- End Blog Post Area Style-------------//


//--------- Start sidebar Area Style-------------//

.single-widget{
	border:1px solid #eee;
	padding:40px 30px;
	margin-bottom:30px;
	.title{
		font-weight:600;
		margin-bottom:30px;
	}
}


.search-widget{
	i{
		color:$primary-color;
	}
	form.example{
		border:1px solid #eee;
	}

	form.example input[type=text] {
		padding: 10px;
		font-size: 14px;
		border: none;
		float: left;
		width: 80%;
		background: #eee;
	}

	form.example button {
		float: left;
		width: 20%;
		padding: 10px;
		background: #eee;
		color: white;
		font-size: 17px;
		border: none;
		cursor: pointer;
	}

	form.example::after {
		content: "";
		clear: both;
		display: table;
	}
}

.protfolio-widget{
	text-align:center;
	h4{
		padding:20px 0;
	}
	ul li{
		padding:15px;
		display:inline-block;;
		a i{
			color:$black;

			&:hover{
				color:$primary-color;
			}
		}
	}
}


.category-widget{
	ul{
		li{
			border-bottom:1px solid #eee;
			padding:10px 20px;
			h6{
				font-weight:300;
				@include transition();
			}
			span{
				color:$black;
				@include transition();
			}
			@include transition();
			&:hover{
				border-color:$primary-color;

				h6,span{
					color:$primary-color;
				}
			}
		}
	}
}


.tags-widget{
	ul{
		li{
			display:inline-block;
			background:$white;
			border:1px solid #eee;
			padding:8px 10px;
			margin-bottom:8px;
			@include transition();
			a{
				font-weight:300;
				color:$black;
			}
			&:hover{
				background-color:$primary-color;
				a{
					color:$white;
				}
			}
		}
	}
}

.recent-posts-widget{
	.single-recent-post{
		.recent-details{
			margin-left:20px;
			h4{
				line-height:1.5em !important;
				font-size:14px;
				@include transition();
			}
			p{
				margin-top:10px;
			}
		}
		&:hover{
			h4{
				color:$primary-color;
			}
		}
		@media(max-width: 960px){
			margin-bottom:20px;
		}
	}

}


//--------- End sidebar Area Style-------------//




/*
################
               End Blog Home  Page style
################
*/


/*
################
               Start Blog Details  Page style
################
*/


.nav-area{
	border-bottom: 1px solid #eee;
	a{
		color:$black;
	}
	.nav-left{
		.thumb{
			padding-right: 20px;
		}
	}
	.nav-right{
		text-align:right;
		.thumb{
			padding-left: 20px;
		}
	}

	@media(max-width:768px){
		.post-details h4{
			font-size: 14px;
		}

	}

	@media(max-width:466px){
		.nav-right{
			margin-top:50px;
		}
	}
}

.comment-sec-area{
	border-bottom:1px solid #eee;
	padding-bottom:50px;
	a{
		color:$black;
	}
	.comment-list{
		padding-bottom:30px;
		&.left-padding{
			padding-left:25px;
		}
	}

	.thumb{
		margin-right:20px;
	}
	.date{
		font-size:13px;
		color:#cccccc;
	}
	.comment{
		color:#777777;
	}
	.btn-reply{
		background-color:$black;
		color: $white;
		border:1px solid $black;
		padding: 8px 30px;
		display:block;
		@include transition();
		&:hover{
			background-color:transparent;
			color: $black;
		}
	}
}

.commentform-area{
	h5{
		font-weight:600!important;
	}
	.form-control{
		border-radius:0;
		font-size: 14px;
		font-weight:400;
	}
	.primary-btn{
		padding-left:50px;
		padding-right:50px;
		background-color:$primary-color!important;
		border:1px solid transparent;
		color:$white;
		&:hover{
			border-color:$primary-color!important;
		}
	}
	textarea{
		height:90%;
	}
	padding-bottom:100px;

	.form-control{
		border:none;
		background:$offwhite;
	}
}




/*
################
               End Blog Details  Page style
################
*/






















