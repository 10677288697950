@font-face {
	font-family: "Poppins";
	src: url("../../font/pxiByp8kv8JHgFVrLDz8Z1xlFQ.woff2");
}
.alert{
	top: 2%;
	text-align: center;
	margin-left: 10%;
	width: 80%;
}

.section-gap {
	padding: 120px 0;
}
.section-title {
	padding-bottom: 30px;
	h2 {
		margin-bottom: 20px;
	}
	p {
		font-size: 16px;
		margin-bottom: 0;
		@include media-breakpoint-down (md) {
			br {
				display: none;
			}
		}
	}
}


.primary-btn {
	background-color:$primary-color;
	line-height: 42px;
	padding-left: 40px;
	padding-right: 40px;
	border:none;
	color: $white;
	display: inline-block;
	font-weight: 500;
	position: relative;
	border:1px solid transparent;
	@include transition();
	cursor: pointer;
	text-transform: uppercase;
	position: relative;
	&:focus {
		outline: none;
	}
	span {
		color: $white;
		position: absolute;
		top: 50%;
		transform: translateY(-60%);
		right: 30px;
		@include transition();
	}
	&:hover {
		color: $primary-color;
		background-color:transparent;
		border-color:$primary-color;
	}
	&.white {
		border: 1px solid $white;
		color: $white;
		span {
			color: $white;
		}
		&:hover {
			background: $white;
			color: $primary-color;
			span {
				color: $primary-color;
			}
		}
	}
}


.overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}


//--------- Start Banner Area -------------//

.banner-area {
	background:url(../../images/header-bg.jpg) center;
	background-size:cover;
	height: 700px;
	.overlay-bg{
		background-color:rgba(#000,.7);
	}
}
.banner-content {
	margin-top:-90px;
	text-align:center;
	h1{
		font-size: 48px;
		font-weight: 700;
		line-height: 1em;
		margin-top:20px;
		span{
			font-weight:700;
		}
		@include media-breakpoint-down(md) {
			font-size: 36px;
		}
		br {
			@include media-breakpoint-down (md) {
				display: none;
			}
		}

		@include media-breakpoint-down (lg) {
			font-size:45px;
		}	

		@media ( max-width:414px ) {
			font-size:32px;
		}
	}
	h6{
		font-size:14px;
		font-weight:400;
		text-transform:uppercase;
		letter-spacing:2px;
	}
	@media(max-width:1280px){
		br{
			display:none;
		}
	}
	@media(max-width:763px){
		p{
			color:$black;	
		}
	}
}

	

//--------- End Banner Area -------------//


//--------- Start Banner Area -------------//


.single-service {
	padding:30px;
	text-align:center;
  	background-color:$offwhite;
	.lnr{
		font-size:36px;
		color:$black;
		color:$primary-color;
	}
	h4{
		margin-bottom:20px;
		margin-top:25px;
		color:$primary-color;
	}

	.image {
	  display: block;
	  width: 100%;
	  height: auto;
	}

	.overlay {
	  position: absolute;
	  top: 0;
	  bottom: 0;
	  left: 0;
	  right: 0;
	  height: 100%;
	  width: 100%;
	  opacity: 0;
	  transition: .5s ease;
	  background-color: $offwhite;
	}

	&:hover .overlay {
	  opacity: 1;
	  cursor:pointer;
	}

	.text {
		p{
		 	color:$text-color;
		}
	  padding:30px;
	  color: white;
	  position: absolute;
	  text-align: center;
	}

	@media(max-width:960px){
		margin-bottom:30px;
		.overlay{
			height:88%;	
		}
	}

}

//--------- End Banner Area -------------//


//--------- Start quote Area -------------//

.quote-left{
	h1{
		font-size:30px;
		text-align:right;
		font-weight:100;
		span{
			font-weight:600;
		}
		@media(max-width:1024px){
			br{
				display:none;				
			}
		}
	}
}


@media(max-width: 960px){
	.quote-left,.quote-right{
		margin-bottom:30px;
		text-align:center;
		h1{
			text-align:center
		}
	}
}


//--------- End quote Area -------------//


//--------- Start exibition Area -------------//

.exibition-area{
	background-color:$offwhite;

	.owl-dots {
	    text-align: center;
	    bottom: 5px;
	    margin-top:20px;
	    width: 100%;
	    -webkit-backface-visibility: hidden;
	    -moz-backface-visibility: hidden;
	    -ms-backface-visibility: hidden;
	    backface-visibility: hidden;
	}

	.owl-dot {
	    height: 10px;
	    width: 10px;
	    display: inline-block;
	    background: rgba(127,127,127, 0.5);
	    margin-left: 5px;
	    margin-right: 5px;
	    @include transition();
	}

	.owl-dot.active {
		@include transition;
	    background:$primary-color;
	}

}

.single-exibition{
	.tags {
		padding:20px 0;
		li{
			display:inline-block;
			background-color:$white;
			padding:5px 15px;
			a{
				color:$black;
				font-weight:600;
			}
			
			border:1px solid #eee;
			@include transition();
			&:hover{
				a{
					color:$white;
				}
				background-color:$primary-color;
							
			}
		}
	}
	h4{
		padding-bottom:20px;
	}

	.date{
		color:$black;
		font-weight:300;
	}
	@media(max-width:414px){
		padding:15px;
	}
}


//--------- End exibition Area -------------//


//--------- Start upcoming-event Area -------------//

.upcoming-event-area{
	.event-left{
		.single-events{
			h4{
				margin-top:40px;
			}
			h6{
				font-weight:300;
				color:#777;
				padding-top:10px;
				padding-bottom:12px;
				span{
					color:$black;
				}
			}
			margin-bottom:50px;
		}
	}

	.event-right{
		.single-events{
			img{
				margin-top:40px;
			}
			h6{
				font-weight:300;
				color:#777;
				padding-top:10px;
				padding-bottom:12px;
				span{
					color:$black;
				}
			}
			margin-bottom:50px;
		}
	}	

}

//--------- End upcoming-event Area -------------//


//--------- start blog  area -------------//

.blog-area{
	background-color:$offwhite;
}

.single-blog{

	.thumb{
		overflow: hidden;
		img{
			width:100%;
			@include transition();
		}
	}
	&:hover{
		img{
			transform:scale(1.1);
		}
		h4{
			color:$primary-color;
		}
	}
	h4{
		margin-bottom:12px;
		@include transition();
	}
	a{
		color:$black;
	}
	.date{
		background-color: #000;
		color: #fff;
		font-weight:100;
		padding: 2px 15px;
		width: 115px;
		text-align: center;
		margin-top: 20px;
	}

	@media(max-width:800px){
		margin-bottom:30px;
	}
}

.meta-bottom{

}

//--------- end blog area  -------------//


//--------- Start gallery  Area -------------//

.gallery-area{
	background-color:#04091e;

	#grid-container {
	  width: 100%;
	  margin: auto;
	  overflow: hidden;
	}

	.grid-item {
	  padding: 15px;
	  opacity: 1;
	  float: left;
	  box-sizing: border-box;
	}

	.grid-item img {
	  width: 100%;
	  height: 100%;
	}

	.grid-item.loaded {
	  opacity: 1;
	  transition: opacity .5s;
	}
}

//--------- End gallery  Area -------------//


//----------- Start Generic Page -------------//

.whole-wrap{
	background-color:#f3f3f3;
}


.generic-banner {
	background-color:$primary-color;
	text-align:center;
	.height {
		height: 600px;
		@include media-breakpoint-down (sm) {
			height: 400px;
		}
	}
	.generic-banner-content {
		h2 {
			line-height: 1.2em;
			margin-bottom: 20px;
			br {
				@include media-breakpoint-down (md) {
					display: none;
				}
			}
		}
		p {
			text-align:center;
			font-size: 16px;
			br {
				@include media-breakpoint-down (md) {
					display: none;
				}
			}
		}
	}
}

.generic-content{
	h1{
		font-weight:600;	
	}
}


.about-generic-area {
	background: $offwhite;
	.border-top-generic {

	}
	p {
		margin-bottom: 20px;
	}
}
.white-bg {
	background: $white;
}
.section-top-border {
	padding: 70px 0;
	border-top: 1px dotted #eee;
}
.switch-wrap {
	margin-bottom: 10px;
	p {
		margin: 0;
	}
}
//----------- End Generic Page -------------//
